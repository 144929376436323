import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"
import styled from "styled-components"

// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import { BgImage, Container, Layout, Section } from "../components"

const AuthorsPage = props => {
  const { data } = props
  return (
    <Layout transparentHeader={true}>
      <Helmet title={data.site.siteMetadata.title} />
      <BgImage
        fluid={data.desktop.childImageSharp.fluid}
        height="60vh"
        mobileHeight="50vh"
        title="desktop"
        overlayColor="rgba(0, 0, 0, 0.7)"
        css={`
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        <Container>
          <h1
            css={`
              font-size: 6rem;
              font-weight: normal;
              color: var(--gray-300);
              text-align: center;
              font-weight: bold;
            `}
          >
            Authors
          </h1>
        </Container>
      </BgImage>
      <Section paddingTop={"1rem"}>
        <Container>
          <h2>All Authors</h2>
          <TagList>
            {data.allMdx.group.map(author => (
              <li key={author.fieldValue}>
                <Link to={`/authors/${kebabCase(author.fieldValue)}/`}>
                  {author.fieldValue} ({author.totalCount})
                </Link>
              </li>
            ))}
          </TagList>
        </Container>
      </Section>
    </Layout>
  )
}

const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    margin: 1rem 1rem 1rem 0;
    /* &:first-child {
      margin-left: 0;
    } */
    > a {
      background: var(--primary);
      border-radius: 0.3rem;
      color: white;
      font-size: 1.6rem;
      padding: 0.8rem 1rem;
      text-decoration: none;
      transition: all 0.2s linear;
      &:hover {
        background: var(--primary-dark);
      }
    }
  }
`

AuthorsPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default AuthorsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___author) {
        fieldValue
        totalCount
      }
    }
    desktop: file(relativePath: { eq: "trees.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
